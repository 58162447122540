<template>
  <div>
    <welcome-conference-banner class='py-8' background-color='#FAFAFA'/>
    <welcome-message
      custom-background-style='background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);' />
  </div>
</template>

<script>
import WelcomeMessage          from '@/views/demo/Welcome.vue'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'


export default {
  name: 'Welcome',
  components: {
    WelcomeMessage,
    WelcomeConferenceBanner,
  },
}
</script>
